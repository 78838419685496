<script>
import DynamicFieldsTable from '@/components/DynamicFields/DynamicFieldsTable';
import dynamicFieldsService from '@/services/DynamicFields/dynamicFieldsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Template', 'Cadastro', 'Label', 'Tipo'];

export default {
  components: {
    DynamicFieldsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Campos Dinâmicos',
        columns: [...tableColumns],
      },
      dynamicFields: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getDynamicFields();
  },
  methods: {
    getDynamicFields() {
      this.dynamicFields = [];
      this.$refs.topProgress.start();

      dynamicFieldsService.getAll()
          .then(function(objs) {
            if (objs.empty) {
              this.$notify({
                title: 'Ooopss',
                message: "Ainda não existem campos dinâmicos cadastrados.",
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            }

            objs.forEach(function(obj) {
              const newObj = obj.data();
              newObj.id = obj.id;

              this.dynamicFields.push(newObj);
            }.bind(this));

            this.isLoading = false;

            this.$refs.topProgress.done();
          }.bind(this));
    },
    deleteObj(preDeletedItem) {
      try {

        const logUser = {
          user: {
              email: this.currentPessoa.email.email
          }
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Campo Dinâmico', '', logContent);

        dynamicFieldsService.delete(preDeletedItem.item.id);

        this.getDynamicFields();

        this.$notify({
          title: "Excluído com sucesso!",
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Campo Dinâmico'"
          :routerType="'dynamic-field'"
        >
          <div class="table-responsive">
            <dynamic-fields-table
              :data="dynamicFields"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteObj"
            ></dynamic-fields-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

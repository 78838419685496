<script>
import AttachmentTable from '@/components/Attachment/AttachmentTable';
import EmptyTermsOfUse from '@/components/Notifications/TermsOfUse/EmptyTermsOfUse';
import GetTermsOfUseError from '@/components/Notifications/TermsOfUse/GetTermsOfUseError';
import DeleteTermOfUseError from '@/components/Notifications/TermsOfUse/DeleteTermOfUseError';
import DeleteTermOfUseSuccess from '@/components/Notifications/TermsOfUse/DeleteTermOfUseSuccess';
import DownloadsError from '@/components/Notifications/Downloads/DownloadsError';

import attachmentsService from '@/services/Attachments/attachmentsService';
import investorsService from '@/services/Investors/investorsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    AttachmentTable,
  },
  data() {
    return {
      table: {
        title: 'Lista de Termos de Uso',
        columns: [...tableColumns],
      },
      attachments: [],
      isLoading: true,
    };
  },
  mounted() {
    this.getAttachments();
  },
  methods: {
    async getAttachments() {
      this.attachments = [];
      this.$refs.topProgress.start();

      try {
        const attachments = await attachmentsService.getAttachments('InvestmentTerms');
        const attachmentsItems = await attachments.items;

        if ( attachmentsItems.length == 0 ) {
            this.$notify({
                component: EmptyTermsOfUse,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
            });
        }
        else {
            await attachmentsItems.map((item) => {
              this.attachments.push(item);
            });
        }
      } catch (e) {
        this.$notify({
          component: GetTermsOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    async deleteAttachments(preDeletedItem) {
      try {
        attachmentsService.deleteAttachment(preDeletedItem.item.name, 'InvestmentTerms');
        await investorsService.updateTermsOfUseAcceptedToFalse();

        this.getAttachments();

        this.$notify({
          component: DeleteTermOfUseSuccess,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.preDeletedItem = {};
      } catch (e) {
        this.$notify({
          component: DeleteTermOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    downloadItem(user) {
      try {
        attachmentsService.downloadAttachment(user.name, 'InvestmentTerms');
      } catch (e) {
        this.$notify({
          component: DownloadsError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Termo de Uso'"
          :routerType="'term-of-use'"
        >
          <div class="table-responsive">
            <attachment-table
              :data="attachments"
              :columns="table.columns"
              :hasActions="true"
              :itemType="'Termo de Uso'"
              :routerType="'term-of-use'"
              :isNonEditable="true"
              :isDownloadable="true"
              thead-classes="text-primary"
              @deleteItem="deleteAttachments"
              @downloadItem="downloadItem"
            ></attachment-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

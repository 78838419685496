<script>
import banksService from '@/services/Banks/banksService';
import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'bank-account-information-form',
  props: {
    error: {
      type: String,
      default: undefined,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    onBack: {
      type: Function,
      required: false,
    },
    initialValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: false,
      defaut: 'Dados Bancários',
    },
    subtitle: {
      type: String,
      required: false,
      defaut: undefined,
    },
  },
  data() {
    return {
      loading: true,
      banks: [],
      value: {
        bank: {
          name: '',
        },
        agency: {
          number: '',
          digit: '',
        },
        account: {
          number: '',
          digit: '',
        },
        ...this.initialValue,
      },
    };
  },
  computed: {},
  components: {
    DoubleBounce,
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
  },
  async mounted() {
    this.loading = true;
    try {
      if (this.readonly) return;
      this.banks = await banksService.getAllBanks().catch((error) => []);
    } catch (error) {
      console.error('error load banks');
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
  <card>
    <double-bounce v-if="loading"></double-bounce>
    <form
      @submit.prevent="handleChange()"
      @change="handleChange()"
      v-if="!loading"
    >
      <h6 slot="header" class="title">
        <a v-if="onBack" v-on:click="onBack()" class="fa fa-arrow-left"></a>
        {{ title }}
      </h6>
      <h6 slot="header" class="title" v-if="subtitle">
        {{ subtitle }}
      </h6>
      <div style="color: red" v-if="error">{{ error }}</div>
      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input required label="Banco*" :disabled="readonly">
            <v-select
              label="name"
              class="style-chooser"
              :options="banks"
              v-model="value.bank.name"
              :disabled="readonly"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrado</em
                  >
                </template>
              </template>
            </v-select>
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência*"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="value.agency.number"
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da agência*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="value.agency.digit"
            :disabled="readonly"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta"
            placeholder="Informe o número da sua conta*"
            v-mask="['########']"
            v-model="value.account.number"
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da conta*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="value.account.digit"
            :disabled="readonly"
          ></base-input>
        </div>
      </div>
    </form>
  </card>
</template>

import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  dynamicFieldsRef() {
    return firebase.firestore().collection('dynamic-fields');
  },

  getAll() {
    return withLog(() =>
      this.dynamicFieldsRef()
        .where('template', '==', 'equity')
        .orderBy('ordenacao', 'asc')
        .get(),
    );
  },

  create(obj) {
    withLog(() => this.dynamicFieldsRef().add(obj));
  },

  delete(obj) {
    withLog(() => this.dynamicFieldsRef().doc(obj).delete());
  },

  get(id) {
    return withLog(() => this.dynamicFieldsRef().doc(id).get());
  },

  update(id, obj) {
    returnwithLog(() => this.dynamicFieldsRef().doc(id).update(obj));
  },
};

import moment from 'moment';
import store from '@/store/index';
import {
  CWLStatusCampanha,
  FinanciamentoPadrao,
  FinanciamentoEstadoPadrao,
  PessoaCaracteristica,
  PessoaPadrao,
  PessoaPadraoPJ,
  EnderecoPadrao,
  TelefonePadrao,
  EmailPadrao,
  DocumentoPadrao,
  TipoDocumento,
  TipoEndereco,
  TipoTelefone,
  TipoEmail,
} from '@/store/cwl_enums';
import axios from 'axios';
import { withLog } from '../logger/with_log';
import pessoasService from '../services/Pessoas/pessoasService';

export default {
  install(Vue, options) {
    Vue.prototype.$CWLCreatePessoaForCookie = function (pessoa) {
      const objCookie = {
        // caracteristicas: pessoa.caracteristicas,
        dados_complementares: {
          birthday: pessoa.dados_complementares.birthday
            ? pessoa.dados_complementares.birthday
            : '',
        },
        nome: pessoa.nome,
        endereco: pessoa.endereco,
        // enderecos: pessoa.enderecos,
        documento: pessoa.documento,
        // documentos: pessoa.documentos,
        email: pessoa.email,
        // emails: pessoa.emails,
        telefone: pessoa.telefone,
        // telefones: pessoa.telefones,
        // photoURL: pessoa.photoURL,
        pessoa_id: pessoa.id,
        disableTour: pessoa.disableTour ? pessoa.disableTour : false,
        caracteristicas: pessoa.caracteristicas || [],
        // tipo_pessoa: pessoa.tipo_pessoa
      };

      return objCookie;
    };

    Vue.prototype.$CWLFinancingStatus = function (status) {
      return {
        'em-criacao': 'Em criação',
        'aguardando-analise': 'Aguardando análise',
        'em-analise': 'Em análise',
        aprovada: 'Aprovada',
        reprovada: 'Reprovada',
        'em-andamento': 'Em Andamento',
        'removida-do-ar': 'Removida do Ar',
        'concluida-com-sucesso': 'Concluída com Sucesso',
        'concluida-sem-sucesso': 'Concluída sem Sucesso',
      }[status ? status : 'em-criacao'];
    };

    Vue.prototype.$CWLFormatDate = function (date) {
      return moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
    };

    Vue.prototype.$CWLVincularCaracteristicaPessoa = async function (
      pessoa,
      caracteristica,
      fazerUpdate = false,
    ) {
      const hasCaracteristica =
        (pessoa.caracteristicas || []).indexOf(caracteristica) !== -1;

      if (!hasCaracteristica) {
        pessoa.caracteristicas = [...pessoa.caracteristicas, caracteristica];
      }
      if (fazerUpdate) {
        pessoasService.updatePessoa(pessoa.id, pessoa);
      }
    };

    Vue.prototype.$CWLVincularCaracteristica = async function (caracteristica) {
      const pessoa = store.getters.getPessoa;
      pessoasService.addPersona(pessoa.id, caracteristica);
    };

    Vue.prototype.$CWLDescricaoCaracteristica = function (caracteristicas) {
      return (caracteristicas ? caracteristicas : []).join(', ');
    };

    Vue.prototype.$CWLNewPessoaPadraoPJ = function () {
      const newPessoaPJ = Object.assign({}, PessoaPadraoPJ);
      newPessoaPJ.caracteristicas = [];
      newPessoaPJ.caracteristicas.push(PessoaCaracteristica.PessoaJuridica);

      newPessoaPJ.endereco = Object.assign({}, EnderecoPadrao);
      newPessoaPJ.endereco.tipo = TipoEndereco.Comercial;
      newPessoaPJ.enderecos = [];

      newPessoaPJ.telefone = Object.assign({}, TelefonePadrao);
      newPessoaPJ.telefone.tipo = TipoTelefone.Comercial;
      newPessoaPJ.telefones = [];

      newPessoaPJ.email = Object.assign({}, EmailPadrao);
      newPessoaPJ.email.tipo = TipoEmail.Comercial;
      newPessoaPJ.emails = [];

      newPessoaPJ.documento = Object.assign({}, DocumentoPadrao);
      newPessoaPJ.documento.tipo = TipoDocumento.CNPJ;
      newPessoaPJ.documentos = [];

      newPessoaPJ.dados_complementares = {};
      newPessoaPJ.dados_complementares.nome_fantasia = '';

      return newPessoaPJ;
    };

    Vue.prototype.$CWLNewPessoaPadrao = function () {
      const newPessoa = Object.assign({}, PessoaPadrao);
      newPessoa.caracteristicas = [];

      newPessoa.endereco = Object.assign({}, EnderecoPadrao);
      newPessoa.enderecos = [];

      newPessoa.telefone = Object.assign({}, TelefonePadrao);
      newPessoa.telefones = [];

      newPessoa.email = Object.assign({}, EmailPadrao);
      newPessoa.emails = [];

      newPessoa.documento = Object.assign({}, DocumentoPadrao);
      newPessoa.documentos = [];

      newPessoa.dados_complementares = {};

      return newPessoa;
    };

    Vue.prototype.$CWLNewFinanciamentoPadrao = function () {
      const newFinanciamento = Object.assign({}, FinanciamentoPadrao);
      newFinanciamento.fields = {};
      newFinanciamento.imageURL =
        'https://firebasestorage.googleapis.com/v0/b/crowdfunding-wl-dev.appspot.com/o/Financings%2Fempty-image.png?alt=media&token=395d0874-22b4-42e4-acad-37be72fa73cd';
      newFinanciamento.dates = {
        created_at: '',
        edited_at: '',
        started_at: '',
        end_at: '',
        approved_at: '',
        reproved_at: '',
      };
      newFinanciamento.users = {
        created_by: {},
        edited_by: {},
        approved_by: {},
        reproved_by: {},
      };
      newFinanciamento.name = '';
      newFinanciamento.categoria = null;
      newFinanciamento.estado = Object.assign({}, FinanciamentoEstadoPadrao);
      newFinanciamento.cidade = null;
      newFinanciamento.is_empresa = false;
      newFinanciamento.dados_complementares = {};
      newFinanciamento.totais = {
        valor_reservado: 0,
        valor_reservado_formatado: '',
        valor_pendente: 0,
        valor_pendente_formatado: '',
        valor_investido: 0,
        valor_investido_formatado: '',
        valor_total: 0,
        valor_total_formatado: '',
        valor_meta_formatado: '',
        porcentagem_reservado_investido: 0,
        porcentagem_investido: 0,
        porcentagem_reservado: 0,
        porcentagem_pendente: 0,
        porcentagem_total: 0,
        porcentagem_reservado_investido_formatado: '',
        porcentagem_investido_formatado: '',
        porcentagem_reservado_formatado: '',
        porcentagem_pendente_formatado: '',
        porcentagem_total_formatado: '',
      };
      newFinanciamento.curadoria = {};

      return newFinanciamento;
    };

    Vue.prototype.$CWLCampanhaCalculosOrdens = function (campanha, orders) {
      var fieldValor = campanha.fields['Valor'];
      if (!fieldValor) {
        fieldValor = campanha.fields['Valor da Captação'];
      }

      var totais = {
        valor_reservado: 0,
        valor_reservado_formatado: '',
        valor_pendente: 0,
        valor_pendente_formatado: '',
        valor_investido: 0,
        valor_investido_formatado: '',
        valor_vencido: 0,
        valor_vencido_formatado: '',
        valor_total: 0,
        valor_total_formatado: '',
        valor_meta: 0,
        valor_meta_formatado: '',
        porcentagem_reservado_investido: 0,
        porcentagem_investido: 0,
        porcentagem_reservado: 0,
        porcentagem_pendente: 0,
        porcentagem_total: 0,
        porcentagem_reservado_investido_formatado: '',
        porcentagem_investido_formatado: '',
        porcentagem_reservado_formatado: '',
        porcentagem_pendente_formatado: '',
        porcentagem_total_formatado: '',
      };

      for (let index = 0; index < orders.length; index++) {
        const order = orders[index];
        totais.valor_reservado += 0; // Reservado foi removido do sistema por enquanto
        totais.valor_pendente +=
          order.status == 'Pendente'
            ? order.item.value * order.item.quantity
            : 0;
        totais.valor_investido +=
          order.status == 'Pago' ? order.item.value * order.item.quantity : 0;
        totais.valor_vencido +=
          order.status == 'Vencido'
            ? order.item.value * order.item.quantity
            : 0;
        totais.valor_total +=
          order.status != 'Vencido'
            ? order.item.value * order.item.quantity
            : 0;
      }
      campanha.totais = totais;
      return this.$CWLCampanhaCalculos(campanha);
    };

    Vue.prototype.$CWLCampanhaCalculos = function (campanha) {
      /**
       * Cálculo de valores $$$
       */
      var fieldValor = campanha.fields['Valor'];
      if (!fieldValor) {
        fieldValor = campanha.fields['Valor da Captação'];
      }
      if (!campanha.totais) {
        campanha.totais = {
          valor_reservado: 0,
          valor_reservado_formatado: '',
          valor_pendente: 0,
          valor_pendente_formatado: '',
          valor_investido: 0,
          valor_investido_formatado: '',
          valor_vencido: 0,
          valor_vencido_formatado: '',
          valor_total: 0,
          valor_total_formatado: '',
          valor_meta: 0,
          valor_meta_formatado: '',
          porcentagem_reservado_investido: 0,
          porcentagem_investido: 0,
          porcentagem_reservado: 0,
          porcentagem_pendente: 0,
          porcentagem_total: 0,
          porcentagem_reservado_investido_formatado: '',
          porcentagem_investido_formatado: '',
          porcentagem_reservado_formatado: '',
          porcentagem_pendente_formatado: '',
          porcentagem_total_formatado: '',
        };
      }

      campanha.totais.valor_meta = fieldValor.value;

      if (!campanha.totais.valor_pendente) {
        campanha.totais.valor_pendente = 0;
      }

      if (!campanha.totais.porcentagem_total) {
        campanha.totais.porcentagem_total = 0;
      }

      let calculatedReserved = 0;
      if (campanha.totais.valor_reservado) {
        calculatedReserved =
          (campanha.totais.valor_reservado / fieldValor.value) * 100;
      }
      let calculatedPaid = 0;
      if (campanha.totais.valor_investido) {
        calculatedPaid =
          (campanha.totais.valor_investido / fieldValor.value) * 100;
      }
      let calculatedPendente = 0;
      if (campanha.totais.valor_pendente) {
        calculatedPendente =
          (campanha.totais.valor_pendente / fieldValor.value) * 100;
      }

      campanha.totais.porcentagem_reservado_investido =
        calculatedPaid + calculatedReserved;
      campanha.totais.porcentagem_reservado_investido_formatado =
        campanha.totais.porcentagem_reservado_investido + ' %';

      campanha.totais.porcentagem_investido = calculatedPaid;
      campanha.totais.porcentagem_investido_formatado =
        campanha.totais.porcentagem_investido + ' %';

      campanha.totais.porcentagem_reservado = calculatedReserved;
      campanha.totais.porcentagem_reservado_formatado =
        campanha.totais.porcentagem_reservado + ' %';

      campanha.totais.porcentagem_pendente = calculatedPendente;
      campanha.totais.porcentagem_pendente_formatado =
        campanha.totais.porcentagem_pendente + ' %';

      const valorReservadoPreFormatado = parseFloat(
        campanha.totais.valor_reservado,
      )
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_reservado_formatado =
        'R$ ' +
        valorReservadoPreFormatado
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      const valorInvestidoPreFormatado = parseFloat(
        campanha.totais.valor_investido,
      )
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_investido_formatado =
        'R$ ' +
        valorInvestidoPreFormatado
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      const valorPendentePreFormatado = parseFloat(
        campanha.totais.valor_pendente,
      )
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_pendente_formatado =
        'R$ ' +
        valorPendentePreFormatado
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      const valorVencidoPreFormatado = parseFloat(campanha.totais.valor_vencido)
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_vencido_formatado =
        'R$ ' +
        valorVencidoPreFormatado
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      const valoresParaSomar =
        campanha.totais.valor_reservado +
        campanha.totais.valor_investido +
        campanha.totais.valor_pendente;
      campanha.totais.valor_total = valoresParaSomar;
      const valorTotalPreFormatado = parseFloat(valoresParaSomar)
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_total_formatado =
        'R$ ' +
        valorTotalPreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      let calculatedTotal = (valoresParaSomar / fieldValor.value) * 100;
      campanha.totais.porcentagem_total =
        parseFloat(calculatedTotal).toFixed(0);
      campanha.totais.porcentagem_total_formatado =
        campanha.totais.porcentagem_total + ' %';

      const valorMetaPreFormatado = parseFloat(fieldValor.value)
        .toFixed(2)
        .replace('.', ',');
      campanha.totais.valor_meta_formatado =
        'R$ ' +
        valorMetaPreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      /**
       * Cálculo de datas
       */
      const fieldFinalDate = campanha.fields['Encerramento da Oferta'];
      const final_date = moment(fieldFinalDate.value, 'YYYY/MM/DD').format(
        'YYYY/MM/DD',
      );
      const finalDate = new Date(final_date);

      const initialDate = new Date();

      const overDue = initialDate > finalDate;

      const metaMinima = fieldValor.value * 0.66667;

      if (overDue) {
        campanha.diasRestante = 0;

        if (campanha.totais.valor_investido > metaMinima) {
          campanha.status = CWLStatusCampanha.ConcluidaComSucesso;
        } else {
          campanha.status = CWLStatusCampanha.ConcluidaSemSucesso;
        }
      } else {
        const diffTime = Math.abs(finalDate - initialDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        campanha.diasRestante = diffDays;
      }
    };
    Vue.prototype.$CWLestornarCampanhaSemSucesso = async function (
      financing_id,
    ) {
      const endpoint =
        process.env.VUE_APP_ENDPOINT_WS + 'estornarCampanhaSemSucesso';
      await axios.post(endpoint, {
        financing_id: financing_id,
      });
    };
    /**
     * Início E-mails
     */
    Vue.prototype.$CWLsendMailAprovarCampanha = async function (obj) {
      const endpoint =
        process.env.VUE_APP_ENDPOINT_WS + 'sendMailAprovarCampanha';
      await axios.post(endpoint, {
        email: obj.email,
        name: obj.name,
        campanha_nome: obj.campanha_nome,
        campanha_link: obj.campanha_link,
      });
    };

    Vue.prototype.$CWLsendMailReprovarCampanha = async function (obj) {
      const endpoint =
        process.env.VUE_APP_ENDPOINT_WS + 'sendMailReprovarCampanha';
      await axios.post(endpoint, {
        email: obj.email,
        name: obj.name,
        campanha_nome: obj.campanha_nome,
        link: obj.link,
      });
    };
    /**
     * Fim E-mails
     */
    /**
     * Início Subcontas
     */
    Vue.prototype.$CWLcreateSubconta = async function (id) {
      const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'subAccountCreate';
      let objReturn = null;
      await axios
        .post(endpoint, { id })
        .then((response) => {
          objReturn = response;
        })
        .catch((error) => {
          console.log(error);
        });
      return objReturn;
    };

    Vue.prototype.$CWLupdateSubconta = async function (
      obj,
      atualizarDadosBancarios = false,
    ) {
      const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'subAccountUpdate';
      let objReturn = null;
      var payload = {
        nome: obj.nome,
        nomeComercial: obj.nomeComercial,
        documento: obj.documento,
        email: obj.email,
        nomeResponsavel: obj.nomeResponsavel,
        documentoResponsavel: obj.documentoResponsavel,
        financingId: obj.financingId,
        financingSubaccountId: obj.financingSubaccountId,
      };
      if (atualizarDadosBancarios) {
        payload.dadosBancarios = {};
        payload.dadosBancarios.codigo_banco = obj.codigo_banco;
        payload.dadosBancarios.agencia_numero = obj.agencia_numero;
        payload.dadosBancarios.agencia_digito = obj.agencia_digito;
        payload.dadosBancarios.conta_numero = obj.conta_numero;
        payload.dadosBancarios.conta_digito = obj.conta_digito;
      }
      console.log(payload);
      await axios
        .post(endpoint, payload)
        .then((response) => {
          objReturn = response;
        })
        .catch((error) => {
          console.log(error);
        });
      return objReturn;
    };
    /**
     * Fim Subcontas
     */
  },
};

<script>
import Vue from 'vue';

import financingService from '@/services/Financing/financingService';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import axios from 'axios';
import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      transacoes: [],
      financing: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    routeType() {
      return this.$route.params.type;
    },
  },
  mounted() {
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.loadTransactions();
  },
  methods: {
    getTransactions(apiKey) {
      let that = this;

      axios
        .get(
          'https://api.safe2pay.com.br/v2/Transaction/List?RowsPerPage=100&Object.IsSandbox=true',
          {
            headers: {
              'x-api-key': apiKey,
            },
          },
        )
        .then((response) => {
          console.log(response);
          var data = response.data.ResponseDetail.Objects;
          data.forEach(function (obj) {
            let newObj = {};
            newObj.IdTransaction = obj.IdTransaction;
            newObj.Reference = obj.Reference;
            newObj.Message = obj.Message;
            newObj.PaymentDate = obj.PaymentDate;
            newObj.Amount = obj.Amount;
            newObj.TaxValue = obj.TaxValue;

            if (obj.Splits && obj.Splits.length > 0) {
              obj.Splits.forEach(function (split) {
                newObj.SplitName = split.Name;
                newObj.SplitAmount = split.Amount;
              });
            }
            that.transacoes.push(newObj);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountKind() {
      let accountKind;
      if (this.routeType == 'campanha') {
        accountKind = 'beneficiary';
      } else if (this.routeType == 'cwl') {
        accountKind = 'platform';
      } else if (this.routeType == 'social-agent') {
        accountKind = 'agent';
      } else if (this.routeType == 'marketplace') {
        accountKind = 'marketplace';
      } else {
        throw new Error('Tipo de transação não suportado!');
      }
      return accountKind;
    },
    async loadTransactions() {
      try {
        this.financing = await financingService.findById(this.routeId);

        const accountKind = this.getAccountKind();
        const token = await financingService.findAccountTokenByIdAndKind(
          this.routeId,
          accountKind,
        );

        if (!token) {
          throw new Error('Não é possível consultar as transações.');
        }

        this.getTransactions(token);
      } catch (error) {
        console.error('load transactions error', error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card>
          <div>
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Reference</th>
                  <th>Message</th>
                  <th>PaymentDate</th>
                  <th>Amount</th>
                  <th>TaxValue</th>
                  <th>Split Name</th>
                  <th>Split Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in transacoes" :key="index">
                  <td>{{ item.IdTransaction }}</td>
                  <td>{{ item.Reference }}</td>
                  <td>{{ item.Message }}</td>
                  <td>{{ item.PaymentDate }}</td>
                  <td>{{ item.Amount }}</td>
                  <td>{{ item.TaxValue }}</td>
                  <td>{{ item.SplitName }}</td>
                  <td>{{ item.SplitAmount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';

import ordersService from '@/services/Orders/ordersService';

import getFinancingSummary from '@/functions/getFinancingSummary';

import { formatCurrency } from '@/formatter/NumberFormatter';

Vue.use(HelperPlugin);

export default {
  name: 'campaign-card',
  props: {
    financing: {
      type: Object,
      default: () => {},
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      summary: {},
    };
  },
  async mounted() {
    this.summary = await getFinancingSummary(this.financing.id).catch(
      (e) => ({}),
    );
  },
  methods: {
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    async calculosDaCampanha() {
      const orders = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );
      return this.$CWLCampanhaCalculosOrdens(this.financing, orders);
    },
  },
  computed: {
    totalFormatted() {
      const values = this.summary.values;
      if (!values) return '';
      return formatCurrency(values.total);
    },
    percentage() {
      const values = this.summary.values;
      if (!values) return '';
      return parseFloat(values.percentage * 100).toFixed(0);
    },
    goalFormatted() {
      const values = this.summary.values;
      if (!values) return '';
      return formatCurrency(values.goal);

    },
  }
};
</script>

<template>
  <div class="card" v-if="financing.id && financing.totais">
    <img :src="financing.imageURL" class="card-img-top" :alt="financing.name" />
    <div class="card-body">
      <h6
        class="card-subtitle text-muted text-center"
        style="font-size: 15px; margin-top: 5px"
      >
        <b>{{ financing.name }}</b>
      </h6>
      <br />
      <div class="alert alert-success" role="alert">
        <h5
          class="card-subtitle text-muted text-center"
          style="color: #fff !important; margin-top: 0px"
        >
          {{ financing.categoria.name }}
        </h5>
      </div>

      <p class="card-text text-right" v-if="!financing.is_empresa">
        <i
          class="fa fa-user-o"
          aria-hidden="true"
          v-tooltip="'Esta campanha é para uma pessoa física.'"
        ></i>
        {{ financing.users.created_by.nome }}
      </p>
      <p class="card-text text-right" v-if="financing.is_empresa">
        <i
          class="fa fa-heart-o"
          aria-hidden="true"
          v-tooltip="'Esta campanha é para uma entidade sem fins lucrativos.'"
        ></i>
        {{ financing.dados_complementares.empresa.nome }}
      </p>
      <p class="card-text text-right" style="font-size: 12px">
        {{ financing.cidade.nome }} / {{ financing.estado.name }}
      </p>

      <hr style="border-top-color: #6c757d; opacity: 0.1" />

      <p
        class="card-text text-left text-muted"
        style="text-transform: uppercase"
      >
        Valor arrecadado
        <span style="font-weight: bolder">{{
         totalFormatted
        }}</span>
      </p>

      <div
        class="progress"
        style="
          height: 1.5rem;
          background-color: #e9ecef;
          border-radius: 0.25rem;
          -webkit-box-shadow: none;
          box-shadow: none;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <div
          v-if="percentage < 6"
          class="progress-bar bg-info"
          role="progressbar"
          :style="
            'color: #000; width: ' + percentage + '%;'
          "
          :aria-valuenow="percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ percentage }} %
        </div>
        <div
          v-if="percentage >= 6"
          class="progress-bar bg-info"
          role="progressbar"
          :style="
            'color: #fff; width: ' + percentage + '%;'
          "
          :aria-valuenow="percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ percentage }} %
        </div>
      </div>

      <p class="card-text text-right text-muted">
        Meta
        <span style="font-weight: bolder">{{
          goalFormatted
        }}</span>
      </p>

      <p v-if="financing.diasRestante" class="card-text text-right">
        <small class="text-muted">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
          {{ financing.diasRestante }} dias restantes
        </small>
      </p>
    </div>
  </div>
</template>
